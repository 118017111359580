.thread_container {
    width: 95vw;
    height: 80vh;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 0.2rem;
    background-color: #181818;
    animation: popin 1s ease-in normal;
  }
  
  .reddit_table_header {
    width: 100%;
    height: 3vw;
    display: flex;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: #4e4d4d solid 1px;
  }
  
  .reddit_table_header > p {
    width: 50%;
    font-size: 1vw;
    font-weight: 100;
    filter: blur(0.5px);
  }
  
  .reddit_table_body {
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
    overflow-y: scroll;
    cursor: default;
  }
  
  /* @keyframes popin {
    0% {
      opacity: 0;
      transform: translatey(50vh);
    }
  } */
  
  .reddit_table_row {
    width: 100%;
    display: flex;
    padding: 1rem 2rem 0 2rem;
    border-bottom: #4e4d4d solid 1px;
  }
  
  /* @keyframes slidein {
    0% {
      opacity: 0;
    }
  } */
  .reddit_table_columns {
    width: 50%;
    display: flex;
    align-self: center;
    justify-self: center;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .reddit_table_columns > p {
    width: 90%;
    font-size: 1.6vh;
    font-weight: 500;
    overflow: hidden;
    filter: brightness(9);
    word-break: break-all;
  }
  
  .cred_pagination {
    right: 6vh;
    bottom: 0.5vh;
    position: fixed;
  }
  