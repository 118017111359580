body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  margin: 0px;
  padding: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  height: 100%;
  width: 100%;
}
.Frame {
  background-color: #111215;
  margin: 5px;
  height: 100vh;
  width: 100vw;
  float: left;
  margin: 0;
  padding: 0;
}
.Logo {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  float: left;
  clear: right;
}
#Logo {
  height: 80px;
  padding: 10px;
  width: 25%;
}
.FormDiv {
  display: flex;
  height: auto;
  width: 100%;
  background-color: #111215;
  margin-top: 20px;
  float: left;
  justify-content: center;
  clear: both;
}
.Form {
  z-index: 2;
  transform-style: preserve-3d;
  align-items: center;
  height: auto;
  width: 40%;
  margin: 10px;
  margin-bottom: 30px;
  background-color: #242424;
  border: 1px solid black;
  float: left;
  clear: both;
  border-radius: 8px;
}
.FormInput {
  height: 50px;
  width: 100%;
  margin-top: 15px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Heading {
  display: flex;
  height: 70px;
  width: 100%;
  float: left;
  margin-top: 10px;
  justify-content: center;
}
.set {
  color: rgb(121, 163, 229);
  font-size: 34px;
  padding: 5px;
  margin: 5px;
}
.Input_border {
  height: 50px;
  width: 70%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid white;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Input {
  height: 50px;
  width: 70%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid white;
  margin: 10px;
  float: left;
  background-color: #161616;
  padding-left: 10px;
  font-size: 18px;
  text-align: center;
  color: white;
}
.buttondiv {
  height: 70px;
  width: 100%;
  margin-top: 25px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.submitbtn1 {
  height: 50px;
  width: 140px;
  float: left;
  margin: 10px;
  border: none;
  border-radius: 8px;
  background-color: rgb(42, 160, 31);
  color: white;
  font-style: bold;
  font-size: 20px;
}
textarea:focus,
input:focus {
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.Card_container {
  width: 90%;
  row-gap: 2%;
  display: flex;
  column-gap: 3%;
  flex-wrap: wrap;
  column-gap: 3%;
  padding-left: 3%;
  align-items: center;
}
.card {
  width: 30%;
  height: 7rem;
  cursor: pointer;
  margin-bottom: 1rem;
  border-radius: 12px;
  background-color: rgba(27, 31, 48, 0.7);
  backdrop-filter: blur(6px) saturate(180%);
  border: 1px solid rgba(255, 255, 255, 0.125);
  -webkit-backdrop-filter: blur(6px) saturate(180%);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
}
.card:hover {
  background-color: rgba(17, 25, 40, 0.5);
  backdrop-filter: blur(10px) saturate(200%);
  border: 1px solid rgba(255, 255, 255, 0.125);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
}
.Card_Inner {
  cursor: pointer;
  width: fit-content;
  border-radius: 8px;
  align-self: stretch;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 1);
}
.Card_Inner:hover {
  backdrop-filter: blur(9px);
  background-color: rgba(0, 0, 0, 0.9);
}
.Card_Inner > h3 {
  font-size: 400;
  font-size: 3vh;
}
.Card_Inner > p {
  font-size: 400;
  font-size: 2vh;
}
.CardLink {
  color: rgb(196, 198, 222);
  text-decoration: none;
  font-size: 30px;
  text-align: center;
}
.CardLink:hover,
.glow {
  color: rgb(0, 123, 255);
  text-shadow: 0 0 10px rgb(0, 123, 255);
}
.AddButton {
  height: 50px;
  width: 92%;
  float: left;
  margin: 10px;
  clear: right;
  display: flex;
  justify-content: flex-end;
}
.UrlBtn {
  border-color: green !important;
  color: green !important;
}
.Popup_Form {
  z-index: 2;
  /* transform-style: preserve-3d; */
  align-items: center;
  height: auto;
  width: auto;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #161616;

  float: left;
  clear: both;
}
.Checkbox {
  height: 20px;
  width: 10%;
}
.Checkbox_label {
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 20%;
  float: left;
  background-color: #242424;
  padding-left: 10px;
  font-size: 18px;
  text-align: center;
  justify-content: center;
  color: rgb(180, 180, 180);
}
.Dropdown1 {
  height: 50px;
  width: 42%;
  border: none;
  background-color: #161616;
  color: rgb(137, 136, 136);
  font-size: 15px;
}
.Input2 {
  height: 50px;
  width: 58%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid white;
  margin: 10px;
  float: left;
  background-color: #161616;
  padding-left: 10px;
  font-size: 18px;
  text-align: center;
  color: white;
}
.AddBodyBtn {
  height: 30px;
  width: 100px;
  float: left;
  margin: 10px;
  border: none;
  border-radius: 8px;
  background-color: rgb(42, 160, 31);
  color: white;
  font-style: bold;
  font-size: 14px;
}
#Dialog {
  background-color: #111215 !important;
}
.radio {
  height: 15px;
  width: 8%;
}
.radio_label {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: 25%;
  float: left;
  background-color: #242424;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  color: rgb(180, 180, 180);
}
.tag {
  color: rgb(196, 198, 222);
  text-decoration: none;
  font-size: 20px;
  text-align: center;
}
.Card_Inner1 {
  z-index: 2;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  width: 30%;
  margin-left: 2.5%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 8px;
  float: left;
}
.FormSection1 {
  border: 1px solid #ccc;
  background: #242424;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;
  border-radius: 8px;
  margin-top: 10px;
  width: 500px;
}
.App {
  text-align: center;
  background-color: black;
  width: 100dvw;
  height: auto;
}
.logBox {
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 20px;
  background: #282c34;
}
.head {
  color: white;
}

.dash_header {
  font-size: 2vw;
  font-weight: 700;

  position: absolute;
  top: 0;
  opacity: 0.8;
  filter: blur(0.6px);
}
