.navbar_container {
  top: 0rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  position: fixed;
  justify-content: space-between;
  animation: zoomin 2s ease-in normal;
}

@keyframes zoomin {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
  }
}

.navbar_menu_container {
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px) saturate(200%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);
}

.navbar_logo {
  width: 3rem;
  margin-left: 1rem;
  align-self: center;
}

.nav_text_field {
  width: 70%;
  color: #fff;
  display: flex;
  font-size: 1vw;
  padding: 0.5vw 1rem;
  align-items: center;
  background: #181818;
  border-radius: 0.2rem;
  justify-content: center;
  animation: expand_it 1s normal;
  box-shadow: rgba(0, 0, 0, 0.7) 0.2rem 0.5rem 0.5rem;
}

.details_navbar_container {
  top: 1.5rem;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  padding: 0 2vw;
  position: fixed;
  justify-content: space-between;
  animation: zoomin 2s ease-in normal;
}
