.thread_container {
  width: 95vw;
  height: 80vh;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 0.2rem;
  background-color: #181818;
  animation: popin 1s ease-in normal;
}
.services_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent; 
  padding: 5px;
  text-align: center; 
  color: green;
  z-index: 1; 
}
.content_section{
  width: 70%;
  
}
.content_scroll {
 
  overflow-y: auto;
  max-height: calc(100vh - 60px); 
}
.status_table_header {
  width: 100%;
  height: 3vw;
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: #4e4d4d solid 1px;
}

.status_table_header > p {
  width: 20%;
  font-size: 1vw;
  font-weight: 100;
  filter: blur(0.5px);
}

.status_table_body {
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
  overflow-y: scroll;
  cursor: default;
}

/* @keyframes popin {
  0% {
    opacity: 0;
    transform: translatey(50vh);
  }
} */

.status_table_row {
  width: 100%;
  display: flex;
  padding: 1rem 2rem 0 2rem;
  border-bottom: #4e4d4d solid 1px;
}

/* @keyframes slidein {
  0% {
    opacity: 0;
  }
} */
.status_table_columns {
  width: 20%;
  display: flex;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  justify-content: space-between;
}

.status_table_columns > p {
  width: 90%;
  font-size: 1.6vh;
  font-weight: 500;
  overflow: hidden;
  filter: brightness(9);
  word-break: break-all;
}

.cred_pagination {
  right: 6vh;
  bottom: 0.5vh;
  position: fixed;
}
