.login_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-color: #02120d;
  /* background-image: radial-gradient(
      at 47% 33%,
      hsl(87.86, 92%, 24%) 0,
      transparent 59%
    ),
    radial-gradient(at 82% 65%, hsl(54.91, 39%, 11%) 0, transparent 55%); */

  background-color: #0d0f1a;
  background-image: radial-gradient(
      at 47% 33%,
      hsl(244, 89%, 11%) 0,
      transparent 59%
    ),
    radial-gradient(at 82% 65%, hsl(182, 88%, 20%) 0, transparent 55%);
  padding-top: 10px;
}

.login_logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.dash_logo {
  display: flex;
  align-items: end;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 2rem;
  z-index: 7;
}
.dash_logo > img {
  width: 5rem;
}
.dash_logo > p {
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.login_logo > img {
  width: 7rem;
}
.login_logo > p {
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
}
.login_form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_input_container {
  gap: 1rem;
  padding: 1rem;
  display: flex;
  width: fit-content;
  border-radius: 0.5rem;
  flex-direction: column;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.4);
}

.input {
  width: 25rem;
}

.form_modal_scraping {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding: 1rem 0;
  padding-top: 3rem;
  position: absolute;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
}

.scraping_input_container {
  gap: 0.5rem;
  width: 600px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 0.5rem;
  /* flex-direction: column; */
}
.scraping_input_container_with_menu {
  gap: 0.5rem;
  width: 600px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 0.5rem;
  background-color: rgb(48, 47, 47, 0.7);
  margin-bottom: 1rem;
}
.scraping_input_container > div,
.scraping_input_container_with_menu > div {
  width: 100%;
}
.grouped_inputs {
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 1px solid white;
}
.grouped_label {
  font-weight: 600;
  opacity: 0.8;
}

.scraping_button_container {
  gap: 0.5rem;
  width: 80%;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  align-items: center;
  border-radius: 0.5rem;
}
.scraping_button_container1{
  gap: 0.5rem;
  width: auto;
  bottom: 0;
  left: 10%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  align-items: center;
  border-radius: 0.5rem;
}

.scraping_input {
  width: 30vw;
}
.scraping_input_with_select {
  width: 53%;
}

.scraping_input_site {
  width: 80%;
}

.form_modal_scraping_close_icon {
  position: sticky;
  top: -2rem;
  margin-left: 96%;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
